.modal {
    position: absolute;
    left: 0;
    translate: -100%;
    transition: ease-in-out 0.5s !important;
    opacity: .9;
    height: 88dvh;
    max-width: 80dvw;
    /* overflow-y: scroll; */

}

.modal > * {
    font-size: small !important;

}
.modal.active {
    translate: 0;
}

.modal__content {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: white;
    border-radius: 4px;

    min-width: 300px;
    width: 100%;
    height: 88dvh;
    padding-inline: 10px;
}

.node_header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    padding-top: 8px;
}

.node_name{
    text-align: start;
    margin-left: 1rem;
}

.field {
    text-align: left;
    padding-bottom: 6px;
}

.field label {
    
    font-size: 12px;
    margin-bottom: 10px;
}

.field input {
    font-size: 12px;
    line-height: 12px;
    padding: 2px 4px;
    width: 100%;
    min-height: 20px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}


@media screen and (max-width: 960px) {
    #selectCardDropdown {
        font-size: .7rem !important;
        /* padding: 0px !important; */
    }
    .node_header {
        justify-content: space-between;
    }
    .modal {
        max-width: 100dvw;
        width: 100dvw !important;
    }
}
